<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import {helpers, required} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core/dist/index.esm";
import store from "@/state/store";
import Swal from "sweetalert2";

/**
 * Form Layouts component
 */
export default {
  setup() {
    return { v$: useVuelidate() };
  },

  components: { Layout, PageHeader},

  validations: function() {
    return {
      sendForm: {
        type: {
          required: helpers.withMessage("전송구분을 선택하세요.", required),
        },
        point: {
          required: helpers.withMessage("포인트을 입력해 주세요.", required)
        },
        memo: {
          required: helpers.withMessage("내용을 입력해 주세요.", required)
        },
      }
    }
  },
  data() {
    return {
      menuId: 400300,
      menuCode: "give",
      phoneTexts: '',
      searchText: '',
      isShowAddPhone: true,
      sendType: [
        { text: '지급 +', value: 'plus' },
        { text: '차감 -', value: 'minus' }
      ],
      sendForm : {
        type: 'plus',
        point: 0,
        memo: ''
      },
      formSubmitted: false,
      receiversItems: [],
      userCount : 0,
      searchUsers: [],
    };
  },
  created() {
    this.$changeMenu(this.menuId, this.menuCode)
  },
  methods: {
    addUser(user) {
      let index = this.receiversItems.indexOf('user')
      if ( index > -1) {
        Swal.fire('전체회원을 먼저 제거해 주세요.', "", "error");
        return
      }
      this.receiversItems.push(user.phone)
      this.searchText = ''
    },
    clearReceiversItems(value) {
      let index = this.receiversItems.indexOf(value)
      if ( index > -1) {
        this.receiversItems.splice(index, 1)
      }
    },
    searchUser() {
      var params = {
        q : this.searchText
      }
      store.dispatch("apiUsers/sendSearch", { params }).then((result) => {
        console.log(result)
        this.searchUsers = result.data.data.data
        this.userCount = result.data.userCount
      }, () => {

      })
    },
    addAll() {
      if(this.receiversItems.length > 0) {
        Swal.fire({
          text: "추가된 데이터가 모두 제거 됩니다. 진행하겠습니까?",
          icon: "warning",
          confirmButtonText: "확인",
          cancelButtonText: "취소",
          showCancelButton: true
        }).then(result => {
          if (result.value) {
            this.receiversItems = []
            this.receiversItems.push('user')
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            console.log('cancel')
          }
        });
      } else {
        this.receiversItems.push('user')
      }
    },
    addPhone() {
      let index = this.receiversItems.indexOf('user')
      if ( index > -1) {
        Swal.fire('전체회원을 먼저 제거해 주세요.', "", "error");
        return
      }

      let phoneReplace = this.phoneTexts.replace(/-/g, '').trim();
      if(phoneReplace.length === 0) {
        Swal.fire('휴대폰 번호를 입력하세요', "", "error");
      }
      else {
        let phones = this.phoneTexts.split("\n");
        for(let i in phones) {
          let text = phones[i].trim();
          if(text && text.length > 0) {
            let data = {id: text, name: text};
            if ( this.receiversItems.indexOf(data.id) > -1) {
              console.log('이미 등록된 번호 입니다.');
            } else {
              this.receiversItems.push(data.id)
              /*
              let newOption = new Option(data.id, data.id, true, true);
              this.$refs.receivers.select2.append(newOption).trigger('change')
              let event = {
                type: 'select2:select',
                params: {
                  data:{ id : data.id, text: data.id, selected : true}
                }
              }
              this.$refs.receivers.select2.trigger(event)
              */
            }
          }
        }
        this.phoneTexts = ''
      }
    },
    resetSendform() {
        this.sendForm.type = 'plus'
        this.sendForm.point = 0
        this.sendForm.memo = ''
        this.receiversItems = []
    },
    getByteLength(s){
      let b, i, c;
      // eslint-disable-next-line no-cond-assign
      for(b=i=0; c = s.charCodeAt(i++); b+=c>>11?3:c>>7?2:1);
      return b;
    },
    encode_utf8( s )
    {
      return unescape( encodeURIComponent( s ) );
    },
    substr_utf8_bytes(str, startInBytes, lengthInBytes) {
      let resultStr = '';
      let startInChars = 0;
      let bytePos = 0;
      for (bytePos = 0; bytePos < startInBytes; startInChars++) {
        let ch = str.charCodeAt(startInChars);
        bytePos += (ch < 128) ? 1 : this.encode_utf8(str[startInChars]).length;
      }

      let end = startInChars + lengthInBytes - 1;
      for (let n = startInChars; startInChars <= end; n++) {
        let ch = str.charCodeAt(n);
        end -= (ch < 128) ? 1 : this.encode_utf8(str[n]).length;
        resultStr += str[n];
      }
      return resultStr;
    },
    confirmSubmit() {
      Swal.fire({
        title: "지급 하겠습니까?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "예",
        cancelButtonText: "아니요"
      }).then(result => {
        if (result.value) {
          this.submitForm()
        }
      });
    },
    submitForm() {
      store.dispatch('notification/clear')

      this.formSubmitted = true
      // stop here if form is invalid
      this.v$.$touch();

      if (this.v$.sendForm.$invalid) {
        this.formSubmitted = false
      } else {
        let params = {
          type: this.sendForm.type,
          point: this.sendForm.point,
          memo: this.sendForm.memo,
          receivers: this.receiversItems
        }
        store.dispatch("apiPoint/store", params).then((result) => {
          this.formSubmitted = false

          if(result.success) {
            Swal.fire(result.message, "", "success");
            this.resetSendform()
          } else {
            Swal.fire(result.message, "", "error");
          }
        }, () => {
          this.formSubmitted = false
        });
      }
    },
  },
  watch: {
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    totalCountText() {
      return '총 ' + this.receiversItems.length + '건'
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader />
      <div class="row gy-2 gx-3 align-items-center">
        <div class="row">
          <div class="col-sm-6 col-xxl-4">
            <div class="card">
              <b-form  @submit.prevent="confirmSubmit">
                <div class="card-body">
                    <div
                        v-if="notification.message"
                        :class="'alert ' + notification.type"
                    >
                    {{ notification.message }}
                    </div>

                    <div class="row gy-2 gx-3 align-items-center">

                    <b-form-group class="mb-3"
                        label="전송 구분"

                        :invalid-feedback="v$.sendForm.type.$error ? v$.sendForm.type.$errors[0].$message : null"
                        :state="!v$.sendForm.type.$error">
                        <b-form-radio-group
                            class="form-control"
                            v-model="sendForm.type"
                            :options="sendType"

                            :state="!v$.sendForm.type.$error"
                            :disabled="formSubmitted"
                        >
                        </b-form-radio-group>
                    </b-form-group>

                    <b-form-group
                        class="mb-3"
                        label="포인트"
                        label-for="point"

                        :invalid-feedback="v$.sendForm.point.$error ? v$.sendForm.point.$errors[0].$message : null"
                        :state="!v$.sendForm.point.$error"
                    >
                        <b-form-input
                        type="text"
                        v-model="sendForm.point"

                        :state="!v$.sendForm.point.$error"
                        :disabled="formSubmitted"
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group
                        class="mb-3"
                        label="메모"
                        label-for="memo"

                        :invalid-feedback="v$.sendForm.memo.$error ? v$.sendForm.memo.$errors[0].$message : null"
                        :state="!v$.sendForm.memo.$error"
                    >
                        <b-form-input
                        type="text"
                        v-model="sendForm.memo"

                        :state="!v$.sendForm.memo.$error"
                        :disabled="formSubmitted"
                        ></b-form-input>
                    </b-form-group>

                    <div>
                        <b-button type="submit" variant="primary" class="w-100"
                                :disabled="formSubmitted">실행</b-button>
                    </div>
                    </div>
                </div>
              
              </b-form>
            </div>
          </div>

          <div class="col-sm-6 col-xxl-4">
              <div class="card">
                  <div class="card-body">
                      <b-form-group
                        class="mb-3"
                        :label="totalCountText"
                        label-for="formrow-email-input"
                    >
                      <div>
                        <div v-if="receiversItems.length === 0">
                          <div class="alert alert-dark text-center mb-1">
                            지급할 번호를 등록해 주세요.
                          </div>
                        </div>
                        <div v-for="value of receiversItems" :key="value">
                          <div class="alert alert-info alert-dismissible mb-1">
                            {{value == 'user'?'전체회원('+userCount+'명)':value}}
                            <button type="button" class="btn-close" @click="clearReceiversItems(value)"></button>
                          </div>
                        </div>
                      </div>
                    </b-form-group>

                    <b-form-group
                    class="mb-3"
                    label="회원 검색"
                    label-for="add-user"
                    >
                      <b-input-group class="mt-3">
                        <b-form-input
                            id="add-user"
                            v-model="searchText"
                            rows="3" placeholder="회원명 또는 휴대폰 입력"
                            :disabled="formSubmitted"
                            @keyup.enter="searchUser"
                        ></b-form-input>
                        <b-button type="button" variant="primary"
                                  @click.prevent="searchUser"
                                  :disabled="formSubmitted">
                          회원검색
                        </b-button>
                        <b-button type="button" variant="success"
                                  @click.prevent="addAll"
                                  :disabled="formSubmitted">
                          전체회원({{userCount}}명) 추가
                        </b-button>
                      </b-input-group>

                    </b-form-group>

                    <div class="mt-1">

                      <div v-for="item of searchUsers" :key="item.uid">
                        <div class="alert alert-warning mb-1 position-relative">
                          <div>{{item.name}} ({{item.phone}})</div>

                          <button v-if="receiversItems.indexOf(item.phone) === -1"  type="button" class="btn btn-sm btn-primary position-absolute" style="top:9px;right:20px;" @click="addUser(item)">추가</button>
                          <button v-if="receiversItems.indexOf(item.phone) > -1"  type="button" class="btn btn-sm btn-primary position-absolute" style="top:9px;right:20px;" :disabled="true">등록됨</button>
                        </div>
                      </div>

                    </div>
                  </div>
              </div>
          </div>
        </div>
      </div>
    <!--  end row -->
  </Layout>
</template>